<template>
  <Layout :tituloPagina="`Bancos | ${modo == 'nuevo'? 'Nuevo': 'Edición'}`">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de' }} Banco
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label>Nombre del banco</label>
            <input
              ref="nombre_banco"
              type="text"
              class="form-control"
              v-model="banco.nombre"
              placeholder="Nombre del banco"
            />
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button @click="cerrar()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
  
            <button
              type="button"
              class="btn btn-success"
              @click="guardar()"
              v-if="modo == 'nuevo'"
              :disabled="bandera_spinner"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Guardar
            </button>
  
            <button
              type="button"
              class="btn btn-success"
              @click="actualizar()"
              v-if="modo == 'edicion'"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import BancoSrv from '@/services/BancoSrv.js'
export default {
  name: 'EdicionBanco',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      banco: {
        nombre: ''
      },
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    // Determinación del modo
    if (this.$route.path.indexOf('bancos/nuevo') != -1) this.modo = 'nuevo'
    else this.modo = 'edicion'

    if (this.modo == 'edicion') this.cargarBanco()
  },

  methods: {
    actualizar: function() {
      var self = this,
        banco = this.banco

      self.bandera_spinner = true

      if (banco.nombre == null || banco.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al banco')
        self.$refs.nombre_banco.select()
        self.bandera_spinner = false
        return
      }

      BancoSrv.actualizar(banco).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarBanco: function() {
      var self = this,
        idBanco = this.$route.params.id

      BancoSrv.bancoJSON(idBanco).then(response => {
        self.banco = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el banco seleccionado'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    guardar: function() {
      var self = this,
        banco = this.banco
      
      self.bandera_spinner = true

      if (banco.nombre == null || banco.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al banco')
        self.$refs.nombre_banco.select()
        self.bandera_spinner = false
        return
      }

      BancoSrv.guardar(banco).then(response => {
        iu.msg.success('Se guardó correctamente el banco')
        self.atras()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar, intentalo nuevamente más tarde'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    }
  },
}
</script>